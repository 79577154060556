import React from 'react';

import {
    grid,
    wrapper,
    sectionBox,
    title as titleClass,
    subtitleText,
} from './sign-up.module.scss';
import { ISection } from '../../models/section.model';
import useTranslations from '../../hooks/use-translations';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';
import { getUrlParamValue } from '../../utils/get-url-param-value';

import Section from '../hoc/section';
import Title from '../atoms/title';
import CandidateDataForm from '../organisms/candidate-data-form';

export interface ISignUpProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

export default function SignUpCandidate({ className, section, TitleTag = 'h2' }: ISignUpProps) {
    const t = useTranslations('SignUp');
    const SubtitleTag = getLessImportantHeading(TitleTag) || 'p';
    const jobOfferPathname = getUrlParamValue('jobOffer');
    const jobOfferId = jobOfferPathname
        ?.slice(jobOfferPathname.lastIndexOf('-') + 1)
        .replace('/', '');

    return (
        <Section
            className={`${className} ${sectionBox}`}
            classes={{ container: grid }}
            style={section.style}
            roundedSection={true}
        >
            <div className={wrapper}>
                <Title className={titleClass} Tag={TitleTag}>
                    {t.title}
                </Title>
                <SubtitleTag className={subtitleText}>{t.subtitle}</SubtitleTag>
                <CandidateDataForm type="register" jobOfferId={jobOfferId} />
            </div>
        </Section>
    );
}
